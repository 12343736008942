import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";

export type OrganizationParams = {
    realm: string;
    orgGuid: string;
};

const EditOrganization = lazy(() => import("../OrganizationDetails"));

export const OrganizationRoute: AppRouteObject = {
    path: "/:realm/organizations/:orgGuid",
    element: <EditOrganization />,
    breadcrumb: (t) => t("organizationDetails"),
    handle: {
        access: "manage-realm",
    },
};

export const toOrganization = (params: OrganizationParams): Partial<Path> => ({
    pathname: generateEncodedPath(OrganizationRoute.path, params),
});