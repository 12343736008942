import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";

export type OrganizationParams = {
    realm: string;
};

const CreateOrganization = lazy(() => import("../CreateOrganization"));

export const CreateOrganizationRoute: AppRouteObject = {
    path: "/:realm/organizations/new",
    element: <CreateOrganization />,
    breadcrumb: (t) => t("createOrganization"),
    handle: {
        access: "manage-realm",
    },
};

export const toAddOrganization = (params: OrganizationParams): Partial<Path> => ({
    pathname: generateEncodedPath(CreateOrganizationRoute.path, params)
});