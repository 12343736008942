import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";

export type PubSubSubTab = "general" | "events";

export type PubSubParams = {
    realm: string;
    tab: PubSubSubTab;
};

const RealmSettingsSection = lazy(() => import("../RealmSettingsSection"));

export const PubSubRoute: AppRouteObject = {
    path: "/:realm/realm-settings/pubsub/:tab",
    element: <RealmSettingsSection />,
    breadcrumb: (t) => t("pubSubTab"),
    handle: {
        access: "manage-realm",
    },
};

export const toPubSubTab = (
    params: PubSubParams,
    ): Partial<Path> => ({
    pathname: generateEncodedPath(PubSubRoute.path, params),
});
