import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";


export type OrganizationsParams = {
    realm: string;
};

const OrganizationsSection = lazy(() => import("../OrganizationsSection"));

export const OrganizationsRoute: AppRouteObject = {
    path: "/:realm/organizations",
    element: <OrganizationsSection />,
    breadcrumb: (t) => t("organizationsList"),
    handle: {
        access: "manage-realm",
    },
};

export const toOrganizations = (params: OrganizationsParams): Partial<Path> => ({
    pathname: generateEncodedPath(OrganizationsRoute.path, params)
});