import type { AppRouteObject } from "../routes";
import {OrganizationsRoute} from "./routes/Organizations";
import {OrganizationRoute} from "./routes/Organization";
import {CreateOrganizationRoute} from "./routes/CreateOrganization";

const routes: AppRouteObject[] = [
    OrganizationsRoute,
    OrganizationRoute,
    CreateOrganizationRoute
];

export default routes;