import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";

export type AddPubSubEventParams = {
    realm: string;
};

const AddPubSubEvent = lazy(() => import("../pubsub/AddPubSubEvent"));

export const AddPubSubEventRoute: AppRouteObject = {
    path: "/:realm/realm-settings/pubsub/events/create",
    element: <AddPubSubEvent />,
    breadcrumb: (t) => t("createPubSubEvent"),
    handle: {
        access: "manage-realm",
    },
};

export const toAddPubSubEvent = (params: AddPubSubEventParams): Partial<Path> => ({
    pathname: generateEncodedPath(AddPubSubEventRoute.path, params),
});
