import Resource from "./resource.js";
export class Organizations extends Resource {
    /**
     * Organizations
     */
    find = this.makeRequest({
        method: "GET",
    });
    findOne = this.makeRequest({
        method: "GET",
        path: "/{orgGuid}",
        urlParamKeys: ["orgGuid"],
        catchNotFound: true,
    });
    create = this.makeRequest({
        method: "POST",
    });
    update = this.makeUpdateRequest({
        method: "PUT",
        path: "/{orgGuid}",
        urlParamKeys: ["orgGuid"],
    });
    del = this.makeRequest({
        method: "DELETE",
        path: "/{orgGuid}",
        urlParamKeys: ["orgGuid"],
    });
    constructor(client) {
        super(client, {
            path: "/realms/{realm}/organizations",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
