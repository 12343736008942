import Resource from "./resource.js";
export class OrganizationIdps extends Resource {
    /**
     * Organization Idps
     */
    find = this.makeRequest({
        method: "GET",
    });
    findOrgIdps = this.makeRequest({
        method: "GET",
        path: "/{orgGuid}",
        urlParamKeys: ["orgGuid"],
        catchNotFound: true,
    });
    delete = this.makeRequest({
        method: "DELETE",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    create = this.makeRequest({
        method: "POST",
    });
    constructor(client) {
        super(client, {
            path: "/realms/{realm}/organization-idp",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
